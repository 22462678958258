import React from "react";
import JACard from "../../shared/JACard";
import { Card, Container, Image } from "react-bootstrap";
import GoFunMeLogo from "../../assets/images/GoFundMeLogo.png";
import PaypalLogo from "../../assets/images/PaypalLogo.png";
import LydiaLogo from "../../assets/images/LydiaLogo.png";
import WesternLogo from "../../assets/images/WesternLogo.png";
import VirementTunisieLogo from "../../assets/images/virement1.png";
import VirementEtrangerLogo from "../../assets/images/virement2.png";

import "../../assets/styles/jadonate.scss";
import { useTranslation } from "react-i18next";
import Cat from "../../assets/images/cat-3.png";
import Dog from "../../assets/images/dog-2.png";
import Slider from "react-slick";
import { isMobile } from "react-device-detect";

export default function JADonate() {
  const { t } = useTranslation();
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: isMobile ? 1 : 4,
    slidesToScroll: isMobile ? 1 : 3,
    autoplay: true,
    speed: 10000,
    autoplaySpeed: 3000,
  };
  return (
    <JACard
      header={t("FAIRE UN DON")}
      LeftImage={Dog}
      RightImage={Cat}
      body={
        <Container className="slider-container">
          <Slider {...settings} className="d-flex gap-5">
            <div>
              {/*               <Image src={GoFunMeLogo} width={200} />
               */}{" "}
              <a
                href="https://www.gofundme.com/f/le-jardin-des-anges?member=27021067&sharetype=teams&utm_campaign=p_na+share-sheet&utm_medium=copy_link&utm_source=customer"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Image src={GoFunMeLogo} width={320} />
                {/*                 {t("Lien ici")}
                 */}{" "}
              </a>
            </div>
            <div>
              <Image src={PaypalLogo} width={320} />
            </div>
            <div>
              <a
                href="https://lydia-app.com/collect/50123-les-jardins-des-anges-a-pour-but-d-accueillir-et-proteger-les-animaux-en-detresses-maltraites-victimes-de-sevices-et-abandons/fr?fbclid=IwAR1bpByXoXdqiijQU4TX_UKtEv7ja52WbixhXGw8ebYIcEntSS5arFLgwAo"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Image src={LydiaLogo} width={320} />
              </a>
            </div>
            <div>
              <Image src={WesternLogo} width={320} />
            </div>
            <div>
              <Image src={VirementTunisieLogo} width={320} />
            </div>
            <div>
              <Image src={VirementEtrangerLogo} width={320} />
            </div>
          {/*   <div className="slick-card">
              <Card>
                <Card.Title>{t("Virement depuis la Tunisie")}</Card.Title>
                <Card.Body className="slick-card-body d-flex flex-column">
                  {" "}
                  <span>RIB - 17535940173554226478 </span>
                  <p>TIZIANA GAMANNOSSI</p>
                </Card.Body>
              </Card>
            </div>
            <div className="slick-card">
              <Card>
                <Card.Title>{t("Virement depuis l'étranger")}</Card.Title>
                <Card.Body className="slick-card-body d-flex flex-column">
                  {" "}
                  <span>{t("Demander le RIB en France")} </span>
                  <p>
                    (
                    {t(
                      "Frais d'un virement d'une banque étrangère vers une banque tunisienne coûte 30€"
                    )}
                    )
                  </p>
                </Card.Body>
              </Card>
              <b></b> <br />{" "}
            </div> */}
          </Slider>
        </Container>
      }
    />
  );
}
