import React, { useState } from "react";
import { Button, Col, Container, Image, Row } from "react-bootstrap";
import AboutImage from "../../assets/images/about.jpg";
import "../../assets/styles/jaabout.scss";
import "../../assets/styles/jabutton.scss";
import { useTranslation } from "react-i18next";

export default function JAAboutBody() {
  const [activeIndex, setActiveIndex] = useState(0);
  const { t, i18n } = useTranslation();
  return (
    <Container>
      <Row>
        <Col>
          {" "}
          <Image
            src={AboutImage}
            className="jaabout-img img-responsive"
            width={450}
          />
        </Col>
        <Col className="jaaboutbody-block">
          <Row className="jaabout-nav">
            <Col>
              {" "}
              <Button
                className="jabutton"
                onClick={() => {
                  setActiveIndex(0);
                }}
                active={activeIndex === 0}
              >
                {t("PRÉSENTATION DE L'ASSOCIATION")}
              </Button>
            </Col>
            <Col>
              <Button
                className="jabutton"
                onClick={() => {
                  setActiveIndex(1);
                }}
                active={activeIndex === 1}
              >
                {t("NOS MISSIONS ET OBJECTIFS")}
              </Button>
            </Col>
          </Row>
          {activeIndex === 0 ? (
            <Col className="d-flex flex-column">
              <Row>
                <p>{t("Bonjour, mon nom est Tiziana Gamannossi.")}</p>
              </Row>
              <Row>
                <p>
                  {t(
                    "Je suis la présidente et fondatrice de l’Association « Les Jardins des Anges » de Kalaa Kebira à Sousse en Tunisie."
                  )}
                </p>
              </Row>
              <Row>
                <p>
                  {t(
                    "Passionnée par la cause animale depuis toujours, je ne pouvais rester indifférente au martyre des chiens errants en Tunisie. Souvent maltraités, sous-alimentés, pourchassés et tués par dizaines chaque jour, nos chiens vivent un véritable calvaire."
                  )}
                </p>
              </Row>
              <Row>
                <p>
                  {t(
                    "Voilà pourquoi j’ai décidé il y a 8 ans de créer un premier refuge à Djerba, Gouvernorat de Medenine."
                  )}
                </p>
              </Row>
              <Row>
                <p>
                  {t(
                    "Ensuite nous avons déménagé plusieurs fois et face à l’augmentation des chiens et chats à recueillir et sauver du martyre qu’ils subissent, en août 2021 nos deux refuges actuels ont vu le jour."
                  )}
                </p>
              </Row>
              <Row>
                <p>
                  {t(
                    "Aujourd’hui, avec mon équipe, nous avons la responsabilité de gérer ces deux refuges où nous hébergeons environs 380 chiens et plus de 130 chats. Mon rôle est de superviser et de participer à toutes les activités du refuge, de veiller au bien-être des animaux, de trouver des solutions pour répondre à leurs besoins et garantir le bon fonctionnement de l’Association dans son ensemble. Mon objectif est de travailler sans relâche avec mon équipe pour offrir le meilleur soutien possible aux animaux et accomplir notre mission avec succès."
                  )}
                </p>
              </Row>
            </Col>
          ) : (
            <Col className="d-flex flex-column">
              <p>
                {t(
                  "Au cours de ces 8 années, j’ai pu acquérir une expérience certaine de la situation des chiens errants en Tunisie et des problèmes liés à l’environnement encore non résolus à ce jour"
                )}{" "}
                ({t("notamment")} :{" "}
                <b>
                  {t(
                    "tenir sous contrôle deux graves maladies largement répandues en Tunisie : la rage et la leishmaniasi chez les chiens et la rage en ce qui concerne les chats"
                  )}{" "}
                </b>{" "}
                {i18n.language === "fr" && (
                  <>
                    et{" "}
                    <b>
                      résoudre les désagréments du tourisme de masse en retirant
                      des hôtels leurs déchets de nourriture et en arrivant
                      après triage au recyclage total de ceux-ci
                    </b>
                    ){" "}
                  </>
                )}
                {t(
                  "et ainsi mettre tout en œuvre pour sauver un maximum de chiens et de chats et leur apporter tous les soins nécessaires ainsi que la vaccination et la stérilisation."
                )}
              </p>
              <p>
                {t(
                  "La tâche est titanesque, chaque jour est un nouveau combat à mener mais ma détermination est sans faille."
                )}
              </p>
            </Col>
          )}
        </Col>
      </Row>
    </Container>
  );
}
